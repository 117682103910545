// sentry.client.config.ts
;globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"v0.2.2-beta-2024-12-03T08-14-51-634Z"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import * as Sentry from "@sentry/nextjs";

Sentry.init({
	dsn:
		process.env.NODE_ENV === "production"
			? "https://fd5e1bceb7a0075c0526ec757e0d5d8b@o4508177147035648.ingest.de.sentry.io/4508200699297872"
			: "",
	integrations: [
		Sentry.replayIntegration({
			blockAllMedia: false,
			maskAllText: false,
		}),
		Sentry.browserTracingIntegration(),
	],
	tracesSampleRate: 1,
	replaysSessionSampleRate: 1.0,
	replaysOnErrorSampleRate: 1.0,
	debug: false, // Enable debug mode
	tracePropagationTargets: [
		"localhost:8000",
		"api.skibs.co",
		"staging-api.skibs.co",
	],
	release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
	includeLocalVariables: true,
});
